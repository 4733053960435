import React from 'react';

const IndexView = React.lazy(() => import('./index/index'));
const HomeView = React.lazy(() => import('./home/index'));
const FundsView = React.lazy(() => import('./funds/index'));
const StrategiesView = React.lazy(() => import('./strategies/index'));
const InsightsView = React.lazy(() => import('./insights/index'));
const ResearchView = React.lazy(() => import('./research/index'));
const AboutView = React.lazy(() => import('./about/index'));
const HomeViewNew = React.lazy(() => import('./homenew/index'));
const AboutViewNew = React.lazy(() => import('./aboutnew/index'));
const ServiceView = React.lazy(() => import('./service/index'));
const ContactView = React.lazy(() => import('./contact/index'));


export {
    IndexView,
    HomeView,
    FundsView,
    StrategiesView,
    InsightsView,
    ResearchView,
    AboutView,
    HomeViewNew,
    AboutViewNew,
    ServiceView,
    ContactView
}